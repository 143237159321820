<template>
  <div class="filters">
    <div class="filter" v-for="(tree, depth) in treeData" :key="depth">
      <div :class="[$slots.right ? 'left-area' : 'item']">
        <div class="label">
          <span v-if="depth === 0">{{ name }}：</span>
        </div>
        <div class="values">
          <span
            v-for="(item, index) in tree"
            :key="item.id"
            :id="`tree_${depth}_${item.id}`"
            :class="{ active: treePath[depth] === index - 1 }"
            @click="onChange(depth, index, item, true)"
          >
            {{ item.name }}
          </span>
        </div>
      </div>
      <div class="right-area" v-if="$slots.right && depth == 0">
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import i18n from "@/locale/i18n";
export default {
  name: "TreeFilter",
  props: {
    name: {
      type: String,
      default: i18n.global.t("category"),
      // 分类
    },
    dataSource: {
      type: Array,
      default: [],
    },
  },
  emits: ["onChange"],
  setup(props, { emit }) {
    let internaData = ref([]),
      treePath = ref([-1]),
      treeData = ref([
        [
          {
            id: 0,
            name: i18n.global.t("CM_All"),
          },
        ],
      ]),
      activeId = 0,
      nowTree = [],
      crumbs = [
        {
          id: 0,
          name: i18n.global.t("CM_All"),
        },
      ];

    watch(
      () => props.dataSource,
      (val) => {
        internaData.value = JSON.parse(JSON.stringify(val));
        treeData.value[0].push(
          ...internaData.value.map((item) => {
            return {
              id: item.id,
              name: item.name,
            };
          })
        );
      },
      { immediate: false, deep: true }
    );

    const onChange = (depth, index, item, reload) => {
      activeId = item.id;
      let d = depth + 1,
        i = index - 1;
      if (depth < treePath.value.length) {
        treeData.value = treeData.value.slice(0, d);
        treePath.value = treePath.value.slice(0, d);
      }
      treePath.value[depth] = i;
      if (i === -1) {
        crumbs = crumbs.slice(0, treePath.value.length - 1);
        if (!crumbs.length) {
          crumbs.push({
            id: 0,
            name: i18n.global.t("CM_All"),
          });
        }
      } else {
        crumbs = [];
        nowTree = internaData.value;
        for (let j = 0; j < treePath.value.length; j++) {
          crumbs.push(nowTree[treePath.value[j]]);
          nowTree = nowTree[treePath.value[j]].list || [];
        }
        if (nowTree.length) {
          treeData.value[d] = [
            {
              id: treeData.value[depth][index].id,
              name: i18n.global.t("CM_All"),
            },
          ];
          treeData.value[d].push(
            ...nowTree.map((item) => {
              return {
                id: item.id,
                name: item.name,
              };
            })
          );
          treePath.value[d] = -1;
        }
      }
      emit("onChange", {
        activeId,
        crumbs,
        reload,
      });
    };
    return {
      treePath,
      treeData,
      onChange,
    };
  },
};
</script>

<style lang="less" scoped>
.filters {
  padding: 0 10px;
}
.filter {
  .mixinFlex(space-between);
  color: #999;
  font-size: 16px;
  line-height: 30px;
  padding: 14px 0;
  border-bottom: 1px dashed #e2e2e2;
  .left-area {
    .mixinFlex(space-between);
    flex: 1;
  }
  .item {
    .mixinFlex(space-between);
    width: 100%;
  }
  .right-area {
    width: 250px;
  }
  .label {
    margin: 5px 0;
    color: #333;
  }
  .values {
    flex: 1;
    .mixinFlex(flex-start);
    flex-wrap: wrap;
    span {
      padding: 0 12px;
      height: 32px;
      margin: 5px 12px 5px 0;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.3s;
      user-select: none;
      border: 1px solid transparent;
      .mixinFlex(center; center);
      &:hover {
        color: @color-theme;
      }
      &.active {
        border-color: @color-theme;
        color: @color-theme;
      }
    }
  }
}
</style>
